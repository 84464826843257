<template>
    <div class="d-flex flex-column slider-stories">
        <div class="row slider-stories__content" v-if="title || text">
            <div class="col-12 col-640-10 col-1200-8">
                <h2
                    class="scale-4 scale-4--bold slider-stories__title"
                    :id="anchorTitle"
                    v-if="title"
                >
                    {{ title }}
                </h2>
                <div class="scale-1 scale-1--lead" v-if="text">
                    <div v-html="text"></div>
                </div>
            </div>
        </div>
        <div class="slider-stories__slider">
            <Slider
                :showPagination="true"
                :wrapAround="true"
                :breakpoints="{
                    1: {
                        itemsToShow: 1.2,
                    },
                    576: {
                        itemsToShow: 1.6,
                    },
                    768: {
                        itemsToShow: 2.2,
                    },
                    1200: {
                        itemsToShow: 3.6,
                    },
                }"
                :slides="slides"
                class="slider--overflow slider--fade-out"
            >
                <template #default="slide">
                    <SlideStory :slide="slide" class="slide__zoom" />
                </template>
            </Slider>
        </div>
    </div>
</template>

<script setup>
import { useAnchorTitle } from '~/composables/useAnchorTitle';

const props = defineProps({
    title: {
        type: String,
    },
    text: {
        type: String,
    },
    slides: {
        type: Object,
    },
});

const anchorTitle = computed(() => useAnchorTitle(props.title));
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.slider-stories {
    color: $color-light;

    &__content {
        margin-bottom: 45px;

        @include media-breakpoint-down(640) {
            margin-bottom: 35px;
        }
    }

    &__title {
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin-bottom: 10px;
    }

    &__slider {
        :deep(.carousel__track) {
            height: 550px;

            @include media-breakpoint-down(800) {
                height: 450px;
            }

            @include media-breakpoint-down(560) {
                height: 350px;
            }
        }
    }
}

.slider-stories--rounded.slider-stories--flipped {
    .slider-stories__image {
        img {
            border-top-right-radius: 12px;
            border-bottom-right-radius: 12px;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;

            @include media-breakpoint-down(640) {
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
}
</style>
