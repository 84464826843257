<template>
    <div class="row justify-content-center">
        <div class="col-12 col-800-10 col-1200-8">
            <h2 class="scale-5 spacer-40" :id="anchorTitle" v-if="title">
                {{ title }}
            </h2>
            <Accordion :id="id" :items="items">
                <slot>
                    <AccordionItem
                        v-for="(item, index) in items"
                        :parentId="id"
                        :id="slugifyString(item.title)"
                        v-bind="item"
                        :content="item.content"
                    >
                    </AccordionItem>
                </slot>
            </Accordion>
        </div>
    </div>
</template>

<script setup>
import Accordion from '@/components/accordion/Accordion.vue';
import AccordionItem from '@/components/accordion/AccordionItem.vue';
import { useAnchorTitle } from '~/composables/useAnchorTitle';
import { slugifyString } from '~/utils/slugify';

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    title: {
        type: String,
        required: true,
    },
    items: {
        type: Object,
        required: true,
    },
});

const anchorTitle = computed(() => useAnchorTitle(props.title));
</script>

<style lang="scss" scoped></style>
