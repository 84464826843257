<template>
    <div class="row justify-content-center">
        <div class="col-12 col-800-10 col-1200-8">
            <h2 class="title scale-3" :id="anchorTitle" v-if="title">
                {{ title }}
            </h2>
            <ul class="steps">
                <li v-for="(item, index) in steps">
                    <div class="scale-1 item__step">
                        {{ t('recipe.instructions.step') }}
                        {{ index + offset + 1 }}
                    </div>
                    <div class="equal-base" v-html="item"></div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import { useAnchorTitle } from '~/composables/useAnchorTitle';

const { t } = useI18n();

const props = defineProps({
    title: {
        type: String,
    },
    steps: {
        type: Array,
        default: () => [],
    },
    offset: {
        type: Number,
        default: 0,
    },
});

const anchorTitle = computed(() => useAnchorTitle(props.title));
</script>

<style lang="scss" scoped>
@import '@/node_modules/bootstrap/scss/mixins';
@import '@/assets/scss/variables.scss';

.title {
    margin-bottom: 30px;
}

.steps {
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;

    li {
        display: flex;
        flex-direction: column;
        margin-bottom: clamp(40px, 0.69vw + 37.78px, 50px);

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.item {
    &__step {
        color: $color-disabled;
        margin-bottom: 10px;
    }
}
</style>
