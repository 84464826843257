<template>
    <svg
        viewBox="0 0 15 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <g
            id="4-Shapes"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <g
                id="1-Symbols/4-Shapes/2-Icon/2-Override/2-Shape/Food"
                fill="#ffffff"
                fill-rule="nonzero"
            >
                <g id="Icon">
                    <path
                        d="M3.075,20 L3.075,10.8249835 C2.225,10.6249875 1.5,10.1749965 0.9,9.4750105 C0.3,8.7750245 0,7.950041 0,7.00006 L0,0 L1.6746,0 L1.6746,7.00006 L3.075,7.00006 L3.075,0 L4.7496,0 L4.7496,7.00006 L6.1746,7.00006 L6.1746,0 L7.8246,0 L7.8246,7.00006 C7.8246,7.950041 7.5288,8.7750245 6.9372,9.4750105 C6.3456,10.1749965 5.6164,10.6249875 4.7496,10.8249835 L4.7496,20 L3.075,20 Z M13.3248,20 L13.3248,11.99976 L10.425,11.99976 L10.425,4.5749085 C10.425,3.24173517 10.8542,2.14595708 11.7126,1.28757425 C12.5706,0.429191416 13.6664,0 15,0 L15,20 L13.3248,20 Z"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
