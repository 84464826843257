<template>
    <div class="row justify-content-center spacer-140-100">
        <div class="col-12 col-800-10 col-1200-8">
            <h2
                v-if="title"
                :id="anchorTitle"
                class="scale-3 scale-3--bold-uppercase spacer-40-30"
            >
                {{ title }}
            </h2>
            <div class="d-flex flex-column flex-800-row box">
                <div
                    v-for="column in facts"
                    class="d-flex flex-column box__column"
                >
                    <div v-for="fact in column" class="fact">
                        <div
                            class="equal-small-2 equal-small-2--bold-uppercase fact__title"
                        >
                            {{ fact.name }}
                        </div>
                        <NuxtLink
                            v-if="fact.to"
                            v-for="item in valueArray(fact.value)"
                            :to="fact?.to"
                            class="scale-1 scale-1--bold link--external link--animated"
                            :target="getTarget(fact.to)"
                        >
                            {{ item }}
                        </NuxtLink>
                        <div
                            v-else
                            v-for="item in valueArray(fact.value)"
                            class="scale-1 scale-1--bold"
                        >
                            {{ item }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useAnchorTitle } from '~/composables/useAnchorTitle';
import { getTarget } from '~/utils/externalLink';

const props = defineProps({
    title: {
        type: String,
        required: false,
    },
    facts: {
        type: Object,
        required: true,
    },
});

function valueArray(value) {
    if (Array.isArray(value)) {
        return value;
    }

    try {
        const values = value.split('\n');
        return values;
    } catch (e) {
        return [];
    }
}

const anchorTitle = computed(() => useAnchorTitle(props.title));
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.box {
    border: 1px solid $color-dark-01;
    border-radius: 12px;
    padding: clamp(35px, 0.35vw + 33.89px, 40px)
        clamp(20px, 1.39vw + 15.56px, 40px);

    &__column {
        width: 50%;

        &:not(:last-child) {
            margin-right: clamp(20px, 1.39vw + 15.56px, 40px);
        }

        @include media-breakpoint-down(800) {
            width: 100%;

            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }

        & > .fact {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
        }
    }
}

.fact {
    &__title {
        color: $color-disabled;
        margin-bottom: 5px;
    }
}
</style>
