<template>
    <div class="row justify-content-center">
        <div
            class="col-12 col-800-10 col-1200-8 d-flex flex-wrap recipe-indicator"
        >
            <div class="recipe-indicator-wrap">
                <IconsWrapper size="19px">
                    <IconsFood />
                </IconsWrapper>
                <span
                    class="equal-base equal-base--tighter-bold people-indicator"
                >
                    {{ t('recipe.instructions.for') }}
                    {{ people }}
                    <template v-if="people > 1">
                        {{ t('recipe.instructions.persons') }}
                    </template>
                    <template v-else>
                        {{ t('recipe.instructions.person') }}
                    </template>
                </span>
            </div>
            <div class="recipe-indicator-wrap">
                <IconsWrapper size="19px" class="recipe-indicator-wrap__icon">
                    <IconsClock />
                </IconsWrapper>
                <span
                    class="equal-base equal-base--tighter-bold time-indicator"
                >
                    {{ timeActive }} {{ t('recipe.instructions.timeActive') }} -
                    {{ timeTotal }} {{ t('recipe.instructions.timeTotal') }}
                </span>
            </div>
        </div>
    </div>
</template>

<script setup>
const { t } = useI18n();

const props = defineProps({
    people: {
        type: Number,
        required: true,
    },
    timeActive: {
        type: Number,
        required: true,
    },
    timeTotal: {
        type: Number,
        required: true,
    },
});
</script>

<style lang="scss" scoped>
.recipe-indicator {
    row-gap: 22px;

    .recipe-indicator-wrap {
        display: flex;

        &__icon {
            flex-shrink: 0;
        }

        .people-indicator {
            margin-left: 14px;
            margin-right: 29px;
        }

        .time-indicator {
            margin-left: 14px;
        }
    }
}
</style>
