import { ComponentMap } from './componentMap';

export function useBodyComponents(page) {
    const bodyComponentsFunc = () => {
        if (!Array.isArray(page?.body)) {
            return [];
        }
        return page?.body
            .filter((c) => {
                if (!ComponentMap[c.type]) {
                    console.error(
                        `got unknow section (${c.type}) to frontend, skipping this section to continue next section`,
                    );
                    return false;
                }
                return true;
            })
            .map((c) => {
                if (typeof c.value === 'string') {
                    c.value = { text: c.value };
                }
                if (c.type === 'form_block') {
                    c.value = {
                        fields: page?.form_fields,
                        url: page.meta.html_url,
                    };
                }
                return { ...ComponentMap[c.type], data: c };
            });
    };
    const bodyComponents = bodyComponentsFunc();
    return bodyComponents;
}
