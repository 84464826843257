<template>
    <div class="row justify-content-center">
        <div class="col-12 col-800-10">
            <div class="slider">
                <Slider
                    :wrapAround="true"
                    :showCurrent="true"
                    :slides="images"
                    :itemsToShow="1"
                    :breakpoints="{}"
                >
                    <template #default="image">
                        <NuxtImg
                            v-if="image.src"
                            sizes="sm:340px md:440px lg:860px"
                            densities="1x 2x"
                            :src="image.src"
                            :alt="image.alt"
                            class="slider__image"
                            loading="lazy"
                        />
                    </template>
                </Slider>
                <hr />
            </div>
        </div>
        <div class="col-12 col-800-10 col-1200-8 content">
            <div v-if="info" class="equal-small-1 content__info">
                {{ info }}
            </div>
            <h2
                v-if="title"
                class="scale-5 scale-5--bold-uppercase content__title"
                :id="slugifyString(title)"
            >
                {{ title }}
            </h2>
            <p v-if="text" class="equal-base" v-html="text"></p>
        </div>
    </div>
</template>

<script setup>
import { slugifyString } from '~/utils/slugify';

const props = defineProps({
    images: {
        type: Array,
        required: true,
    },
    info: {
        type: String,
        required: false,
    },
    title: {
        type: String,
        required: false,
    },
    text: {
        type: String,
        required: false,
    },
});

const anchorTitle = computed(() => useAnchorTitle(props.title));
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.slider {
    @include media-breakpoint-down(800) {
        :deep(.slider) {
            margin-left: $padding-container-negative;
            margin-right: $padding-container-negative;
        }

        :deep(.slider__navigation) {
            margin-left: (-$padding-container-negative);
            margin-right: (-$padding-container-negative);
        }
    }

    &__image {
        height: 60vh;
        min-height: 400px;
        max-height: clamp(400px, 27.78vw + 311.11px, 800px);
        width: 100%;
        object-fit: cover;
    }

    :deep(.slider) {
        .slider__slide {
            padding-right: 0 !important;
            padding-left: 0 !important;
        }
    }

    hr {
        color: $color-dark-01;
        opacity: 1;
        margin-top: 10px;
        margin-bottom: 0;
    }
}

.content {
    &__info {
        color: #999999;
        margin-top: 30px;
    }

    &__title {
        margin-top: 35px;
    }

    p {
        margin-top: 10px;
        margin-bottom: 0;
    }
}
</style>
