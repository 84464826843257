<template>
    <div class="row">
        <div class="col-12">
            <MagazineTeaser :teaser="teaser" :small="true" class="highlight" />
        </div>
    </div>
</template>

<script setup>
const props = defineProps({
    image: {
        type: Object,
    },
    title: {
        type: String,
    },
    button: {
        type: Object,
    },
});

const teaser = reactive({
    image: props.image,
    title: props.title,
    button: props.button,
});
</script>

<style lang="scss" scoped>
.highlight {
    height: 60vh;
    min-height: 425px;
    max-height: clamp(425px, 17.71vw + 368.33px, 680px);
}
</style>
