<template>
    <div class="accordion" :id="props.id">
        <slot>
            <accordion-item
                v-for="(item, index) in items"
                :key="item.title"
                :title="item.title"
                :content="item.content"
                :parentId="id"
                :id="slugifyString(item.title)"
            />
        </slot>
    </div>
</template>

<script setup>
import AccordionItem from '@/components/accordion/AccordionItem.vue';
import { slugifyString } from '~/utils/slugify';

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    items: {
        type: Array,
        required: false,
    },
});
</script>

<style lang="scss" scoped>
.accordion {
    background-color: unset;
}
</style>
