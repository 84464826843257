<template>
    <svg
        viewBox="0 0 20 20"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <title>Graphic-Why-2-Perfect</title>
        <g
            id="Brand-Assets"
            stroke="none"
            stroke-width="1"
            fill="none"
            fill-rule="evenodd"
        >
            <g
                id="1-Symbols/0-Brand-Assets/Graphic/Why/2-Perfect"
                fill="#ffffff"
                fill-rule="nonzero"
            >
                <g id="Label">
                    <path
                        d="M7.5,1.43709594 L7.5,0 L12.5,0 L12.5,1.43709594 L7.5,1.43709594 Z M9.3125,10.9628125 L10.6875,10.9628125 L10.6875,5.96412838 L9.3125,5.96412838 L9.3125,10.9628125 Z M10,18 C8.97233333,18 8.0035,17.7962698 7.0935,17.3888094 C6.18383333,16.9810058 5.38883333,16.4266262 4.7085,15.7256708 C4.02783333,15.0250586 3.48966667,14.2063619 3.094,13.2695806 C2.698,12.3324561 2.5,11.3347446 2.5,10.276446 C2.5,9.21814749 2.698,8.22060759 3.094,7.28382631 C3.48966667,6.34670176 4.02783333,5.5278334 4.7085,4.82722124 C5.38883333,4.1262658 6.18383333,3.57188626 7.0935,3.16408261 C8.0035,2.75662223 8.97233333,2.55289204 10,2.55289204 C10.9026667,2.55289204 11.7603333,2.71028091 12.573,3.02505864 C13.3853333,3.33949311 14.118,3.77561645 14.771,4.33342869 L15.875,3.19652154 L16.854,4.20470279 L15.75,5.34212484 C16.264,5.95691973 16.6841667,6.6719492 17.0105,7.48721323 C17.3368333,8.30247726 17.5,9.23222152 17.5,10.276446 C17.5,11.3347446 17.302,12.3324561 16.906,13.2695806 C16.5103333,14.2063619 15.9721667,15.0250586 15.2915,15.7256708 C14.6111667,16.4266262 13.816,16.9810058 12.906,17.3888094 C11.9963333,17.7962698 11.0276667,18 10,18 L10,18 Z M10,16.5840151 C11.6943333,16.5840151 13.1353333,15.9690486 14.323,14.7391155 C15.5103333,13.5088392 16.104,12.0212827 16.104,10.276446 C16.104,8.53160936 15.5103333,7.04765719 14.323,5.82458951 C13.1353333,4.6018651 11.6943333,3.99050289 10,3.99050289 C8.30566667,3.99050289 6.86466667,4.6018651 5.677,5.82458951 C4.48966667,7.04765719 3.896,8.53160936 3.896,10.276446 C3.896,12.0212827 4.48966667,13.5088392 5.677,14.7391155 C6.86466667,15.9690486 8.30566667,16.5840151 10,16.5840151 Z"
                        id="Icon"
                    ></path>
                </g>
            </g>
        </g>
    </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
