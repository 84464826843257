<template>
    <div class="row justify-content-center">
        <div class="col-12 col-800-10 col-1200-8 matrix">
            <div class="spacer-60-50">
                <h2
                    class="scale-3 scale-3--bold-uppercase"
                    :class="{ 'spacer-25-20': text }"
                    :id="anchorTitle"
                    v-if="title"
                >
                    {{ title }}
                </h2>
                <p class="equal-base" v-html="text"></p>
            </div>
            <div v-if="matrixItems.length == 1" class="matrix__highlight">
                <ProductTeaserDetail
                    :slide="matrixItems[0]"
                    class="matrix__slide--horizontal"
                    parent-type="category"
                    :parent-title="title"
                />
            </div>
            <div v-if="matrixItems.length == 2" class="matrix__grid">
                <ProductTeaserDetail :slide="matrixItems[0]" />
                <ProductTeaserDetail :slide="matrixItems[1]" />
            </div>
            <div v-if="matrixItems.length == 3">
                <Slider
                    :showPagination="true"
                    :wrapAround="true"
                    :breakpoints="{
                        0: {
                            itemsToShow: 1.2,
                        },
                        800: {
                            itemsToShow: 2.0,
                        },
                        1200: {
                            itemsToShow: 2.5,
                        },
                    }"
                    :slides="matrixItems"
                    class="slider--overflow slider--fade-out"
                >
                    <template #default="slide">
                        <ProductTeaser :slide="slide" />
                    </template>
                </Slider>
            </div>
            <div v-if="matrixItems.length >= 4">
                <Slider
                    :showPagination="true"
                    :wrapAround="true"
                    :breakpoints="{
                        0: {
                            itemsToShow: 1.2,
                        },
                        800: {
                            itemsToShow: 2.0,
                        },
                        1200: {
                            itemsToShow: 3.5,
                        },
                    }"
                    :slides="matrixItems"
                    class="slider--overflow slider--fade-out"
                >
                    <template #default="slide">
                        <ProductTeaser :slide="slide" />
                    </template>
                </Slider>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useAnchorTitle } from '~/composables/useAnchorTitle';

const props = defineProps({
    title: {
        type: String,
        required: true,
    },
    text: {
        type: String,
    },
    items: {
        type: Array,
        default: () => [],
    },
});

const matrixItems = computed(() => {
    return props.items.filter((i) => i?.price);
});

const anchorTitle = computed(() => useAnchorTitle(props.title));
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.matrix {
    p {
        margin: 0;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        gap: clamp(25px, 2.43vw + 17.22px, 60px);

        :deep(.slide) {
            height: unset;
        }

        @include media-breakpoint-down(800) {
            grid-template-columns: repeat(1, minmax(0, 1fr));
        }
    }

    @include media-breakpoint-up(800) {
        .matrix__slide--horizontal {
            align-items: center;
            display: flex;
            flex-direction: row !important;
            justify-content: space-between;

            :deep(.slide__image) {
                width: 50%;
            }

            :deep(.slide__content) {
                width: 50%;
            }
        }
    }
}
</style>
