<template>
    <div
        class="component-wrapper"
        :class="{
            'component-wrapper--hero': iComponent.component.__name === 'Hero',
            'component-wrapper--recipe':
                iComponent.component.__name === 'Preparation',
        }"
        v-for="iComponent in bodyComponents.slice(0, 2)"
        :key="iComponent.data.id"
    >
        <component :is="iComponent.component" v-bind="iComponent.data.value">
        </component>
    </div>
    <DelayHydration>
        <div
            class="component-wrapper"
            :class="{
                'component-wrapper--hero':
                    iComponent.component.__name === 'Hero',
                'component-wrapper--recipe':
                    iComponent.component.__name === 'Preparation',
            }"
            v-for="iComponent in bodyComponents.slice(2)"
            :key="iComponent.data.id"
        >
            <component
                :is="iComponent.component"
                v-bind="iComponent.data.value"
            >
            </component>
        </div>
    </DelayHydration>
</template>

<script setup>
const props = defineProps({
    page: {
        type: Object,
        required: true,
    },
});

const bodyComponents = useBodyComponents(props.page);
</script>

<style lang="scss">
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.component-wrapper {
    margin-bottom: clamp(80px, 4.17vw + 66.67px, 140px);

    &--hero {
        margin-bottom: 0;

        @include media-breakpoint-down(880) {
            margin-bottom: 60px;
        }
    }
}
.component-wrapper--recipe ~ .component-wrapper--recipe {
    margin-top: -60px;
}
</style>
