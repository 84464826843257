<template>
    <div class="row">
        <div class="col-12">
            <div class="banner">
                <div
                    class="d-flex flex-column align-items-center banner__header"
                >
                    <img
                        :src="bannerPath"
                        alt="banner image"
                        densities="1x 2x"
                        class="banner__image"
                        loading="lazy"
                    />
                    <h3
                        class="scale-1 scale-1--serif banner__subheading"
                        v-if="subheading"
                    >
                        {{ subheading }}
                    </h3>
                </div>
                <div class="row justify-content-center">
                    <slot></slot>
                    <CmsContentBannerItem
                        v-for="item in banner_items"
                        :icon="item.icon"
                        :title="item.title"
                        :text="item.text"
                        :key="item.icon"
                    />
                </div>
                <FormButton
                    v-if="button"
                    class="l-button--outlined banner__button"
                    :label="button.label"
                    :to="button.to"
                    :rel="button.rel"
                    component="NuxtLink"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { locale } = useI18n();

const props = defineProps({
    title: {
        type: String,
        default: '/images/cms/banner/why-luma-de.svg',
    },
    subheading: {
        type: String,
    },
    button: {
        type: Object,
    },
    banner_items: {
        type: Array,
    },
});

const bannerPath = ref('/images/cms/banner/why-luma-de.svg');

onMounted(() => {
    if (locale.value === 'fr') {
        bannerPath.value = '/images/cms/banner/why-luma-fr.svg';
    }
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.banner {
    background-color: $color-dark-03;
    border-radius: 12px;
    text-align: center;
    padding: 40px;

    @include media-breakpoint-down(640) {
        padding: 20px;
    }

    &__header {
        padding-bottom: 40px;

        @include media-breakpoint-down(640) {
            padding-bottom: 34px;
        }
    }

    &__image {
        max-height: clamp(39px, 4.38vw + 25px, 102px);
        aspect-ratio: auto 537/91;
        width: 100%;
    }

    &__subheading {
        margin-top: 15px;
    }

    &__button {
        margin-top: 15px;

        @include media-breakpoint-down(640) {
            margin-top: 5px;
        }
    }
}
</style>
