import Hero from '@/components/cms/content/Hero.server.vue';
import ImageText from '@/components/cms/content/ImageText.vue';
import Banner from '@/components/cms/content/Banner.vue';
import Gallery from '@/components/cms/media/Gallery.vue';
import Accordion from '@/components/cms/content/Accordion.vue';
import Contact from '@/components/cms/content/ContactPerson.vue';
import Facts from '@/components/cms/content/Facts.vue';
import InfoBox from '@/components/cms/content/InfoBox.vue';
import Quotation from '@/components/cms/content/Quotation.vue';
import Carousel from '@/components/cms/media/Carousel.vue';
import ImageText02 from '@/components/cms/content/ImageText02.vue';
import SliderTestimonials from '@/components/cms/content/SliderTestimonials.vue';
import SliderImages from '@/components/SliderImages.vue';
import Video from '@/components/cms/media/Video.vue';
import Image from '@/components/cms/media/Image.vue';
import RecipeInstructions from '@/components/cms/recipe/Instructions.vue';
import RecipeTime from '@/components/cms/recipe/RecipeTime.vue';
import RecipeIngredients from '@/components/cms/recipe/RecipeIngredients.vue';
import RecipePreparation from '@/components/cms/recipe/Preparation.vue';
import Text from '@/components/cms/content/Text.vue';
import ProductGrid from '@/components/cms/product/Grid.vue';
import Matrix from '@/components/cms/product/Matrix.vue';
import ProductSlider from '@/components/cms/product/Slider.vue';
import ProductImageTextSliderBottom from '@/components/cms/product/ImageTextSliderBottom.vue';
import ProductImageTextSliderSide from '@/components/cms/product/ImageTextSliderSide.vue';
import SliderStories from '@/components/SliderStories.vue';
import Form from '@/components/form/Form.vue';
import MagazineHighlight from '@/components/cms/magazine/Highlight.vue';
import MagazineGrid from '@/components/cms/magazine/Grid.vue';
import MagazineMoreLikeThis from '@/components/cms/magazine/MoreLikeThis.vue';
import MagazineContentMatrix from '@/components/cms/magazine/Matrix.vue';

export const ComponentMap = {
    hero_block: {
        component: Hero,
    },
    image_text_block: {
        component: ImageText,
    },
    banner_block: {
        component: Banner,
    },
    image_gallery_block: {
        component: Gallery,
    },
    accordion_block: {
        component: Accordion,
    },
    contact_block: {
        component: Contact,
    },
    facts_block: {
        component: Facts,
    },
    info_box_block: {
        component: InfoBox,
    },
    quotation_block: {
        component: Quotation,
    },
    carousel_block: {
        component: Carousel,
    },
    image_text_two_block: {
        component: ImageText02,
    },
    slider_testimonials_block: {
        component: SliderTestimonials,
    },
    slider_images_block: {
        component: SliderImages,
    },
    video_block: {
        component: Video,
    },
    image_info_block: {
        component: Image,
    },
    recipe_instructions_block: {
        component: RecipeInstructions,
    },
    recipe_time_block: {
        component: RecipeTime,
    },
    recipe_ingredients_block: {
        component: RecipeIngredients,
    },
    recipe_preparation_block: {
        component: RecipePreparation,
    },
    text: {
        component: Text,
    },
    product_grid_block: {
        component: ProductGrid,
    },
    product_matrix: {
        component: Matrix,
    },
    product_slider_block: {
        component: ProductSlider,
    },
    image_text_product_slider_side_block: {
        component: ProductImageTextSliderSide,
    },
    image_text_product_slider_bottom_block: {
        component: ProductImageTextSliderBottom,
    },
    recommended_product_slider_bottom_block: {
        component: ProductImageTextSliderBottom,
    },
    link_image_slider_block: {
        component: SliderStories,
    },
    form_block: {
        component: Form,
    },
    magazine_highlight: {
        component: MagazineHighlight,
    },
    magazine_grid_manual: {
        component: MagazineGrid,
    },
    magazine_grid_automatic: {
        component: MagazineGrid,
    },
    magazine_more_like_this: {
        component: MagazineMoreLikeThis,
    },
    magazine_content_matrix: {
        component: MagazineContentMatrix,
    },
};
