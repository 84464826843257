<template>
    <div class="accordion-item">
        <div class="accordion-header" :id="id">
            <button
                class="accordion-button"
                :class="{ collapsed: collapsed }"
                type="button"
                data-bs-toggle="collapse"
                :data-bs-target="'#' + collapse"
                :aria-controls="collapse"
            >
                <h3
                    class="equal-base equal-base--tighter-bold"
                    :id="slugifyString(title)"
                >
                    {{ title }}
                </h3>
            </button>
        </div>
        <div
            :id="collapse"
            class="accordion-collapse collapse"
            :class="{ show: !collapsed }"
            :aria-labelledby="id"
            :data-bs-parent="'#collapse-' + parentId"
        >
            <div class="accordion-body equal-base">
                <slot>
                    <div v-html="content"></div>
                </slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { slugifyString } from '~/utils/slugify';

const props = defineProps({
    id: {
        type: String,
        required: true,
    },
    title: {
        type: String,
        default: '<TITLE>',
    },
    parentId: {
        type: String,
        required: true,
    },
    collapsed: {
        type: Boolean,
        default: true,
    },
    content: {
        type: String,
        default: '',
    },
});

const collapse = computed(() => 'collapse-' + props.id);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';

.accordion-item {
    background-color: unset;
    border: unset;
    border-top: 1px solid $color-dark-01;

    &:last-child {
        border-bottom: 1px solid $color-dark-01;
    }

    &:last-of-type {
        border-bottom-right-radius: unset;
        border-bottom-left-radius: unset;
    }

    &:first-of-type {
        border-top-left-radius: unset;
        border-top-right-radius: unset;
    }

    .accordion-header {
        padding: 0;
        color: $color-light;

        .accordion-button {
            padding: 22px 0 22px 0;
            background-color: unset;
            box-shadow: unset;

            &::after {
                background-color: $color-dark-02;
                border-radius: 50%;

                flex-shrink: 0;
                width: 30px;
                height: 30px;
                margin-left: auto;
                content: '';
                background-image: url('@/assets/icons/plus.svg');
                background-repeat: no-repeat;
                background-size: 14px;
                background-position: center;

                transform: rotate(45deg);
            }
        }
    }

    .accordion-button.collapsed::after {
        transform: rotate(0deg);
    }

    .accordion-body {
        padding: 0 0 30px 0;
    }
}
</style>
