<template>
    <div class="youtube">
        <iframe
            ref="youtube"
            width="1760"
            height="1320"
            :src="getSource()"
            allowfullscreen
        >
        </iframe>
    </div>
</template>

<script setup>
const props = defineProps({
    video: {
        type: Object,
        required: true,
    },
    autoplay: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const youtube = ref(null);

function getSource() {
    const embed_url = 'https://www.youtube-nocookie.com/embed/';
    const video_id = props.video?.id;
    const autoplay = props.autoplay ? 1 : 0;
    const mute = props.autoplay ? 1 : 0;
    return `${embed_url}${video_id}?autoplay=${autoplay}&mute=${mute}&vq=highres&modestbranding=1&showinfo=0&rel=0&showinfo=0&hd=1&autohide=1`;
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.youtube {
    position: relative;
    width: 100% !important;
    height: 0 !important;
    padding-bottom: 56.25%;

    iframe {
        width: 100% !important;
        height: 100% !important;
        position: absolute;
        top: 0;
        left: 0;
    }

    @include media-breakpoint-down(640) {
        margin: 0 !important;
    }
}
</style>
