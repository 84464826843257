<template>
    <div class="row" :class="{ 'justify-content-center': center }">
        <div class="col-12 col-800-10 video-col">
            <MediaVideo
                v-if="source?.type == 'wagtail'"
                class="video"
                :video="source?.value?.video"
                :autoplay="source?.value?.autoplay"
                playsinline
            />
            <MediaYoutube
                v-if="source?.type == 'youtube'"
                class="video"
                :video="source?.value?.video"
                :autoplay="source?.value?.autoplay"
            />
        </div>
        <div
            v-if="title || text || button?.to"
            class="col-12 col-800-10 col-1200-8 content"
        >
            <h2
                v-if="title"
                class="scale-5 scale-5--bold-uppercase content__title"
                :id="anchorTitle"
            >
                {{ title }}
            </h2>
            <p v-if="text" class="equal-base" v-html="text"></p>
            <FormButton
                v-if="button?.to"
                component="NuxtLink"
                class="l-button--outlined content__button"
                :label="button?.label"
                :to="button?.to"
                :rel="button?.rel"
            />
        </div>
    </div>
</template>

<script setup>
import { useStructuredVideo } from '~/composables/structuredData/video';
import { useAnchorTitle } from '~/composables/useAnchorTitle';

const props = defineProps({
    source: {
        type: Object,
        required: true,
    },
    title: {
        type: String,
        required: false,
    },
    text: {
        type: String,
        required: false,
    },
    button: {
        type: Object,
        required: false,
    },
    center: {
        type: Boolean,
        default: true,
    },
});

const anchorTitle = computed(() => useAnchorTitle(props.title));
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.video {
    height: 60vh;
    max-height: clamp(400px, 27.78vw + 311.11px, 800px);

    @include media-breakpoint-down(640) {
        width: -webkit-fill-available;
        margin-left: $padding-container-negative;
        margin-right: $padding-container-negative;
    }
}

.content {
    margin-top: 35px;

    &__title {
        margin-bottom: 10px;
    }

    p {
        margin: 0;
    }

    &__button {
        margin-top: 15px;
    }
}

@media print {
    .video-col {
        display: none;
    }
}
</style>
