<template>
    <div class="row slider-testimonials">
        <div class="col-12">
            <h2
                class="scale-5 scale-5--bold-uppercase slider-testimonials__title"
                v-if="title"
                :id="anchorTitle"
            >
                {{ title }}
            </h2>
            <div class="slider-testimonials__slider">
                <Slider
                    :showPagination="true"
                    :wrapAround="true"
                    :breakpoints="{
                        1: {
                            itemsToShow: 1.2,
                        },
                        560: {
                            itemsToShow: 1.5,
                            snapAlign: 'center',
                        },
                        800: {
                            itemsToShow: 2.2,
                            snapAlign: 'center',
                        },
                        1200: {
                            itemsToShow: 2.5,
                            snapAlign: 'center',
                        },
                    }"
                    :slides="slides"
                    class="slider--overflow slider--fade-out"
                >
                    <template #default="slide">
                        <SlideTestimonial :slide="slide" :size="slide?.size" />
                    </template>
                </Slider>
            </div>
        </div>
    </div>
</template>

<script setup>
import { useAnchorTitle } from '~/composables/useAnchorTitle';

const props = defineProps({
    title: {
        type: String,
    },
    slides: {
        type: Object,
    },
});

const anchorTitle = computed(() => useAnchorTitle(props.title));
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.slider-testimonials {
    &__title {
        margin-bottom: 30px;
    }

    &__slider {
        :deep(.carousel__track) {
            min-height: 600px;
        }

        :deep(.slider__slide) {
            padding-right: 15px !important;
            padding-left: 15px !important;
        }
    }
}
</style>
