<template>
    <div class="row justify-content-center">
        <div class="col-12 col-800-10 col-1200-8">
            <slider
                :showPagination="true"
                :wrapAround="true"
                :breakpoints="{
                    1: {
                        itemsToShow: 1.2,
                    },
                    560: {
                        itemsToShow: 1.6,
                    },
                    800: {
                        itemsToShow: 2.2,
                    },
                    1200: {
                        itemsToShow: 3.6,
                    },
                }"
                :slides="items"
            >
                <template #default="slide">
                    <ProductTeaser
                        :slide="slide"
                        parent-type="category-teaser"
                        :parent-title="seoStore.pageTitle"
                    />
                </template>
            </slider>
        </div>
    </div>
</template>

<script setup>
import Slider from '@/components/Slider.vue';
import { useSeoStore } from '~/stores/seo';

const props = defineProps({
    items: {
        type: Array,
        default: () => [],
    },
});

const seoStore = useSeoStore();
</script>

<style lang="scss" scoped></style>
