<template>
    <div class="row justify-content-center gallery">
        <div class="col-12">
            <div class="gallery__content" v-if="title || text">
                <h2
                    class="scale-4 scale-4--bold-uppercase gallery__title"
                    v-if="title"
                    :id="anchorTitle"
                >
                    {{ title }}
                </h2>
                <div
                    class="scale-1 scale-1--lead gallery__text"
                    v-if="text"
                    v-html="text"
                ></div>
            </div>
            <LoadMore
                @next="loadNext"
                :name="loadMore?.name"
                :page="loadMore?.page"
                :size="loadMore?.size"
                :pages="loadMore?.pages"
                :total="loadMore?.total"
                :initial="{
                    from: loadMore?.page,
                    to: loadMore?.page,
                }"
            >
                <template #previous>
                    <div class="gallery__grid">
                        <SlideImage
                            v-for="item in previous"
                            :slide="item"
                            class="gallery__item slide__zoom"
                        />
                    </div>
                </template>
                <template #default>
                    <div
                        class="gallery__grid"
                        :class="{
                            'gallery__grid--previous': previous.length > 0,
                            'gallery__grid--next': next.length > 0,
                        }"
                    >
                        <SlideImage
                            v-for="item in current"
                            :slide="item"
                            class="gallery__item slide__zoom"
                        />
                    </div>
                </template>
                <template #next>
                    <div class="gallery__grid">
                        <SlideImage
                            v-for="item in next"
                            :slide="item"
                            class="gallery__item slide__zoom"
                        />
                    </div>
                </template>
            </LoadMore>
        </div>
    </div>
</template>

<script setup>
import { useAnchorTitle } from '~/composables/useAnchorTitle';

const props = defineProps({
    title: {
        type: String,
        default: '',
    },
    text: {
        type: String,
        default: '',
    },
    loadMore: {
        type: Object,
    },
    items: {
        type: Array,
        default: () => [],
    },
});

const anchorTitle = computed(() => useAnchorTitle(props.title));

const previous = ref([]);
const current = ref([]);
const next = ref([]);

current.value = props.items.slice(
    0,
    props.loadMore?.page * props.loadMore?.size,
);

next.value = getNext(props.items, props.loadMore?.page);

function getNext(items, page) {
    const data = items.slice(
        page * props.loadMore?.size,
        Math.min(
            page * props.loadMore?.size + props.loadMore?.preview_size,
            props.loadMore?.total,
        ),
    );
    return data;
}

function loadNext(page) {
    const data = props.items.slice(
        (page - 1) * props.loadMore?.size,
        Math.min(page * props.loadMore?.size, props.loadMore?.total),
    );

    current.value = current.value.concat(data);
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.gallery {
    &__grid {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: clamp(25px, 2.43vw + 17.22px, 60px);
        row-gap: clamp(25px, 2.43vw + 17.22px, 60px);
        margin: 0;

        @include media-breakpoint-down(640) {
            grid-template-columns: 1fr;
        }

        &--previous {
            margin-top: clamp(25px, 2.43vw + 17.22px, 60px);
        }

        &--next {
            margin-bottom: clamp(25px, 2.43vw + 17.22px, 60px);
        }
    }

    &__content {
        margin-bottom: 45px;
        width: 66%;

        @include media-breakpoint-down(640) {
            margin-bottom: 35px;
            width: 100%;
        }
    }

    &__title {
        margin-bottom: 10px;
    }

    &__item {
        width: 100%;
        height: clamp(270px, 4.17vw + 256.67px, 330px);
    }
}
</style>
