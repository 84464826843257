<template>
    <div class="row justify-content-center">
        <div class="col-12 spacer-180-100">
            <h2
                class="scale-3 scale-3--bold-uppercase spacer-30"
                :id="anchorTitle"
                v-if="title"
            >
                {{ title }}
            </h2>
            <div class="grid">
                <MagazineTeaser
                    v-for="item in tiles"
                    :teaser="item"
                    class="grid__item"
                >
                </MagazineTeaser>
                <div
                    v-if="showAll?.button && showAll?.display_tile"
                    class="d-800-flex show-all"
                >
                    <FormButton
                        class="l-button--secondary show-all__button"
                        :label="showAll?.button?.label"
                        :to="showAll?.button?.to"
                        component="NuxtLink"
                    />
                </div>
            </div>
            <div
                v-if="showAll?.button && showAll?.display_button"
                class="show-all-button"
            >
                <FormButton
                    class="l-button--secondary"
                    :label="showAll?.button?.label"
                    :to="showAll?.button?.to"
                    component="NuxtLink"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { useAnchorTitle } from '~/composables/useAnchorTitle';
const props = defineProps({
    title: {
        type: String,
    },
    tiles: {
        type: Array,
        default: () => [],
    },
    limit: {
        type: Number,
        default: 6,
    },
    showAll: {
        type: Object,
    },
});

const anchorTitle = computed(() => useAnchorTitle(props.title));
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: clamp(25px, 2.43vw + 17.22px, 60px);
    row-gap: clamp(25px, 2.43vw + 17.22px, 60px);
    margin: 0;

    @include media-breakpoint-down(1200) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media-breakpoint-down(800) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &__item {
        height: clamp(425px, 17.71vw + 368.33px, 680px) !important;
        width: 100% !important;
    }
}

.show-all {
    background-color: $color-dark-03;
    height: clamp(425px, 17.71vw + 368.33px, 680px) !important;
    width: 100% !important;
    display: flex;
    align-items: center;
    justify-content: center;

    &__button {
        margin: auto;
        @include media-breakpoint-down(800) {
            width: unset !important;
        }
    }
}

.show-all-button {
    margin-top: 50px;
    text-align: center;
}
</style>
