<template>
    <div class="row justify-content-center">
        <div class="col-12 col-800-10 col-1200-8">
            <div
                class="info-box"
                :class="{ 'info-box--expanded': show || !showMore }"
            >
                <h3
                    v-if="title"
                    class="scale-1 scale-1--bold-uppercase info-box__title"
                    :id="slugifyString(title)"
                >
                    {{ title }}
                </h3>
                <div
                    ref="textContainer"
                    class="equal-base info-box__text"
                    v-html="text"
                ></div>
                <a
                    v-if="showMore"
                    class="d-block equal-small-2 equal-small-2--bold-uppercase link link--grey info-box__more"
                    @click="toggle"
                >
                    {{ t('cms.infoBox.more') }}
                </a>
                <FormButton
                    v-if="button?.to"
                    component="NuxtLink"
                    class="l-button--outlined info-box__button"
                    :label="button?.label"
                    :to="button?.to"
                    :rel="button?.rel"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { slugifyString } from '~/utils/slugify';

const { t } = useI18n();

const props = defineProps({
    title: {
        type: String,
    },
    text: {
        type: String,
        required: true,
    },
    showMore: {
        type: Boolean,
        required: false,
        default: false,
    },
    button: {
        type: Object,
        required: false,
    },
});

const show = ref(false);
const showMore = ref(props.showMore);

function toggle() {
    show.value = !show.value;
}

const textContainer = ref(null);

onMounted(() => {
    try {
        setTimeout(() => {
            let lineHeight = parseFloat(
                getComputedStyle(textContainer.value).lineHeight,
            );
            let numberOfLines = textContainer.value.scrollHeight / lineHeight;
            if (numberOfLines <= 5) {
                showMore.value = false;
            }
        }, 10);
    } catch {}
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.info-box {
    border: 1px solid $color-dark-01;
    border-radius: 12px;
    padding: clamp(35px, 0.35vw + 33.89px, 40px)
        clamp(20px, 1.39vw + 15.56px, 40px);

    &__title {
        margin-bottom: 10px;
    }

    &__text {
        margin-bottom: 0;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;

        :deep(p:last-child) {
            margin-bottom: 0;
        }
    }

    &__more {
        display: block;
        cursor: pointer;
        margin-top: 10px;
    }

    &__button {
        margin-top: 15px;
    }

    &--expanded {
        .info-box {
            &__text {
                display: block;
                -webkit-line-clamp: unset;
            }

            &__more {
                display: none !important;
            }
        }
    }
}
</style>
