<template>
    <div class="row justify-content-center">
        <div class="col-12 col-800-10 col-1200-8">
            <h2 v-if="title" class="scale-5" :id="anchorTitle">{{ title }}</h2>
            <div v-if="description" class="contact-person__description">
                {{ description }}
            </div>
            <div class="d-flex align-items-center flex-column flex-560-row box">
                <NuxtImg
                    v-if="person.image?.src"
                    class="box__image"
                    :src="person.image?.src"
                    :alt="person.image?.alt"
                    :placeholder="140"
                    width="140"
                    height="140"
                    densities="1x 2x"
                    loading="lazy"
                    format="webp"
                />
                <div class="align-self-center d-flex flex-column box__content">
                    <div class="equal-base equal-base--tighter-bold-uppercase">
                        {{ person?.name }}
                    </div>
                    <div
                        v-if="person?.function"
                        class="equal-base equal-base--tighter"
                    >
                        {{ person?.function }}
                    </div>
                    <div class="equal-base equal-base--tighter">
                        {{ person?.info }}
                    </div>
                    <ul
                        v-if="person?.email || person?.phone"
                        class="box__options"
                    >
                        <li v-if="person?.email">
                            <FormButton
                                class="l-button--secondary l-button--icon-only box__button"
                                :size40="true"
                                data-bs-toggle="modal"
                                data-bs-target="#contact-person-email"
                            >
                                <IconsMail />
                            </FormButton>
                        </li>
                        <li v-if="person?.phone">
                            <FormButton
                                class="l-button--secondary l-button--icon-only box__button"
                                :size40="true"
                                data-bs-toggle="modal"
                                data-bs-target="#contact-person-phone"
                            >
                                <IconsPhone />
                            </FormButton>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <Teleport to="body">
            <ContactEmail id="contact-person-email" :items="[person?.email]" />
            <ContactPhone id="contact-person-phone" :items="[person?.phone]" />
        </Teleport>
    </div>
</template>

<script setup>
import { useAnchorTitle } from '~/composables/useAnchorTitle';

const props = defineProps({
    title: {
        type: String,
    },
    description: {
        type: String,
    },
    person: {
        type: Object,
        required: true,
    },
});

const anchorTitle = computed(() => useAnchorTitle(props.title));
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.contact-person {
    &__description {
        margin-top: 10px;
    }
}

.box {
    margin-top: 30px;
    padding: 40px;
    border: 1px solid $color-dark-01;
    border-radius: 12px;

    @include media-breakpoint-down(560) {
        padding: 35px 20px;
    }

    &__image {
        width: clamp(100px, 2.78vw + 91.11px, 140px);
        height: clamp(100px, 2.78vw + 91.11px, 140px);
        border-radius: 50%;
        margin-right: 30px;
        object-fit: cover;
        background-color: $color-dark-01;

        @include media-breakpoint-down(560) {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    &__content {
        @include media-breakpoint-down(560) {
            text-align: center;
        }
    }

    &__info {
        margin-top: 10px;
    }

    &__options {
        margin-top: 10px;
        margin-bottom: 0;
        padding-left: 0;
        list-style: none;
        display: flex;

        li:not(:last-child) {
            margin-right: 15px;
        }

        @include media-breakpoint-down(560) {
            align-self: center;
        }
    }

    &__button {
        border-color: $color-dark-02;
        background-color: $color-dark-02;
    }
}
</style>
