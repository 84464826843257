<template>
    <template v-for="index in getLoopCount()">
        <div v-if="index <= props.items.length" class="row">
            <div class="col-12 grid">
                <ProductTeaserDetail
                    class="grid__item"
                    v-for="(item, indexInner) in getItems(
                        index,
                        index > props.content.length,
                    )"
                    :slide="item"
                    :index="indexInner"
                    parent-type="category"
                />
            </div>
        </div>
        <div v-if="index <= props.content.length" class="grid__content">
            <CmsBody :page="getContent(index, index == getLoopCount())" />
        </div>
    </template>
</template>

<script setup>
const props = defineProps({
    items: {
        type: Array,
        default: () => [],
    },
    content: {
        type: Object,
    },
});

function getLoopCount() {
    var count = Math.ceil(props.items.length / 6);

    if (props.content.length < count + 1) {
        count = props.content.length + 1;
    }

    return count;
}

function getItems(index, remaining, count = 6) {
    var end = index * count;

    if (remaining) {
        end = props.items.length;
    }

    return props.items.slice((index - 1) * count, end);
}

function getContent(index, remaining, count = 1) {
    var end = index * count;

    if (remaining) {
        end = props.content.length;
    }

    return {
        body: props.content.slice((index - 1) * count, end),
    };
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: clamp(25px, 2.43vw + 17.22px, 60px);
    row-gap: clamp(25px, 2.43vw + 17.22px, 60px);
    margin: 0;

    @include media-breakpoint-down(800) {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }

    @include media-breakpoint-down(640) {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    &__item {
        min-height: clamp(425px, 17.71vw + 368.33px, 680px);
    }

    &__content {
        margin-top: clamp(80px, 4.17vw + 66.67px, 140px);
    }
}
</style>
