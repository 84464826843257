<template>
    <div class="row justify-content-center">
        <div class="col-12 col-800-10 col-1200-8">
            <div ref="richtext" class="text equal-base" v-html="text"></div>
        </div>
    </div>
</template>

<script setup>
import { onMounted, onBeforeUnmount, onUpdated, nextTick } from 'vue';

const props = defineProps({
    text: {
        type: String,
        required: true,
    },
});

// convert html links to nuxt links
// - https://github.com/nuxt-community/markdownit-module/issues/16
// - https://github.com/d-darwin/darwin-vue-ui/blob/main/src/mixins/linkClickRouting.js

const router = useRouter();
const richtext = ref(null);

var links = [];

onMounted(() => {
    if (router) {
        addListeners();
    }
});

onBeforeUnmount(() => {
    if (router) {
        removeListeners();
    }
});

onUpdated(() => {
    if (router) {
        removeListeners();
        nextTick(() => {
            addListeners();
        });
    }
});

function navigate(event) {
    const href = event.target.getAttribute('href');
    const target = event.target.getAttribute('target');
    if (href && href[0] === '/' && target !== '_blank') {
        event.preventDefault();
        navigateTo({ path: href });
    }
}

function addListeners() {
    links = richtext.value.getElementsByTagName('a');
    for (let i = 0; i < links.length; i++) {
        links[i].addEventListener('click', navigate, false);
    }
}

function removeListeners() {
    for (let i = 0; i < links.length; i++) {
        links[i].removeEventListener('click', navigate, false);
    }
    links = [];
}
</script>

<style lang="scss" scoped>
.text {
    :deep(p:last-child) {
        margin-bottom: 0;
    }
    :deep(h3),
    :deep(h4) {
        margin-bottom: 13px;
        margin-top: 0;
    }
    :deep(h5),
    :deep(h6) {
        margin-bottom: 5px;
        margin-top: 0;
    }
}
</style>
