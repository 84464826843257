<template>
    <div class="row justify-content-center">
        <div class="col-12 col-800-10 col-1200-8">
            <form action="." class="row" v-if="!done" ref="formElement">
                <div
                    v-for="field in props.fields"
                    :class="fieldClass(field)"
                    class="mb-4"
                >
                    <component
                        :error="errorFields[field.clean_name]"
                        :is="componentMap[field.field_type]"
                        v-bind="attrs(field)"
                        v-model="formData[field.clean_name]"
                        @update:selected="
                            (e) => (formData[field.clean_name] = e)
                        "
                    ></component>
                </div>
                <div class="col">
                    <FormButton :label="t('form.submit')" @click="submit" />
                </div>
            </form>
            <div v-else>
                <Message
                    :message="t('form.success')"
                    class="message--success"
                />
            </div>
        </div>
    </div>
</template>

<script setup>
import axios from 'axios';
import { ref } from 'vue';
import { getCookie } from '~/api/utils';
import Input from '~/components/form/Input';
import Checkbox from '~/components/form/Checkbox';
import RadioSelect from '~/components/form/RadioSelect';
import { useI18n } from 'vue-i18n';
import { scrollTo } from '~/utils/scrollTo';

const { t } = useI18n();

const props = defineProps({
    fields: {
        type: Object,
        required: true,
    },
    url: {
        type: String,
        required: true,
    },
});

const formData = ref({});
const formElement = ref(null);
const done = ref(false);
const route = useRoute();
const errorFields = ref({});
const config = useRuntimeConfig();
function fieldClass(field) {
    if (field.layout === '50') {
        return 'col-12 col-800-6';
    }
    if (field.layout === '100') {
        return 'col-12';
    }
    return 'col-12 col-800-6';
}

function getChoices(choicesString) {
    if (!choicesString) return [];
    let splitter = ',';
    if (choicesString.includes('\r\n')) {
        splitter = '\r\n';
    }
    if (choicesString.includes('\n')) {
        splitter = '\n';
    }
    return choicesString
        .split(splitter)
        .map((choice) => ({ id: choice.trim(), text: choice.trim() }));
}

function attrs(field) {
    if (field.field_type === 'singleline') {
        return {
            id: field.clean_name,
            label: field.label,
            placeholder: field.help_text,
            required: field.required,
        };
    }
    if (field.field_type === 'email' || field.field_type === 'number') {
        return {
            id: field.clean_name,
            label: field.label,
            placeholder: field.help_text,
            required: field.required,
            type: field.field_type,
        };
    }
    if (field.field_type === 'checkbox') {
        return {
            id: field.clean_name,
            label: field.label,
            required: field.required,
        };
    }
    if (field.field_type === 'radio') {
        return {
            id: field.clean_name,
            label: field.label,
            preselect: true,
            required: field.required,
            choices: getChoices(field.choices),
        };
    }
    if (field.field_type === 'date') {
        return {
            id: field.clean_name,
            label: field.label,
            required: field.required,
            type: field.field_type,
        };
    }

    // if (field.field_type === 'multiline') {}
    // if (field.field_type === 'url') {}
    // if (field.field_type === 'checkboxes') {}
    // if (field.field_type === 'dropdown') {}
    // if (field.field_type === 'multiselect') {}
    // if (field.field_type === 'datetime') {}
    // if (field.field_type === 'hidden') {}
}

const bodyFormData = computed(() => {
    const res = new FormData();
    for (const [key, value] of Object.entries(formData.value)) {
        res.append(key, value);
    }
    res.append('csrfmiddlewaretoken', getCookie('csrftoken'));
    return res;
});

function submit() {
    const headers = {
        'Content-Type': 'multipart/form-data',
        'X-CSRFTOKEN': getCookie('csrftoken'),
    };
    errorFields.value = {};
    let url;
    try {
        const baseUrl = new URL(config.public.apiBase).host;
        url = `https://${baseUrl}${route.path}`;
    } catch (e) {
        url = 'https://api.luma-delikatessen.de';
    }

    axios
        .request({
            method: 'POST',
            url: url,
            data: bodyFormData.value,
            headers,
            withCredentials: true,
        })
        .then(() => {
            done.value = true;
            scrollTo(formElement.value);
        })
        .catch((err) => {
            scrollTo(formElement.value);
            const fields = err?.response?.data;
            if (fields) {
                for (let key in fields) {
                    errorFields.value[key] = fields[key][0].message;
                }
            }
        });
}
onMounted(() => {
    props.fields.forEach((field) => {
        if (field.default_value) {
            formData.value[field.clean_name] = field.default_value;
        }
    });
});

const componentMap = {
    singleline: Input,
    email: Input,
    number: Input,
    date: Input,
    checkbox: Checkbox,
    radio: RadioSelect,
};
</script>

<style lang="scss" scoped></style>
