<template>
    <div class="row justify-content-center">
        <div class="col-12 col-800-10 col-1200-8">
            <h2
                v-if="info"
                class="equal-small-2 equal-small-2--bold-uppercase info"
                :id="slugifyString(info)"
            >
                {{ info }}
            </h2>
            <Quotation v-if="quote" :text="quote" />
            <div
                v-if="person"
                class="d-flex align-items-start align-items-560-center flex-column flex-560-row person"
            >
                <NuxtImg
                    v-if="person.image?.src"
                    class="person__image"
                    :src="person.image?.src"
                    :alt="person.image?.alt"
                    :placeholder="100"
                    width="100"
                    height="100"
                    densities="1x 2x"
                    loading="lazy"
                    format="webp"
                />
                <div class="d-flex flex-column">
                    <h3 class="equal-base equal-base--tighter-bold-uppercase">
                        {{ person.name }}
                    </h3>
                    <div
                        v-if="person.function"
                        class="equal-base equal-base--tighter person__info"
                    >
                        {{ person.function }}
                    </div>
                    <div
                        v-if="person.website"
                        class="equal-base equal-base--tighter person__info"
                    >
                        <NuxtLink
                            :to="person.website"
                            class="equal-base equal-base--tighter person__info link"
                            :target="getTarget(person.website)"
                        >
                            {{ stripProtocol(person.website) }}
                        </NuxtLink>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <CmsContentInfoBox
        v-if="person.description"
        class="description"
        :text="person.description"
        :showMore="true"
    />
</template>

<script setup>
import { getTarget } from '~/utils/externalLink';
import { slugifyString } from '~/utils/slugify';

const props = defineProps({
    info: {
        type: String,
    },
    quote: {
        type: String,
        required: true,
    },
    person: {
        type: Object,
    },
});

function stripProtocol(s) {
    return s.replace(/(^\w+:|^)\/\//, '');
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.info {
    color: $color-disabled;
    margin-bottom: 20px;
}

.person {
    margin-top: 35px;

    &__image {
        width: clamp(80px, 1.39vw + 75.56px, 100px);
        height: clamp(80px, 1.39vw + 75.56px, 100px);
        border-radius: 50%;
        margin-right: 30px;
        object-fit: cover;
        background-color: $color-dark-01;

        @include media-breakpoint-down(560) {
            margin-right: 0;
            margin-bottom: 20px;
        }
    }

    &__info {
        margin-top: 10px;
    }
}

.description {
    margin-top: clamp(50px, 2.08vw + 43.33px, 80px);
}
</style>
